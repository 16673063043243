import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class WorkTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          img={post.frontmatter.img.publicURL}
        />
        <article style={{ padding: `20px 15px 15px 15px`, maxWidth: 1200, margin: "0 auto" }}>
          <header>
            <h1>
              {post.frontmatter.title}
            </h1>
            <p>
              {post.frontmatter.category}
            </p>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr/>
          {/* <p>{post.frontmatter.video}</p> */}
          {/* <div >
            <iframe width="auto" height="auto" src={`https://www.youtube.com/embed/${post.frontmatter.video}?controls=1&showinfo=0&autoplay=1&loop=1&playlist=${post.frontmatter.video}&wmode=transparent&modestbranding=1&rel=0&version=3" frameborder="0" allow="accelerometer; autoplay; loop; encrypted-media; gyroscope; picture-in-picture`} allowfullscreen></iframe>
          </div> */}
        </article>
      </Layout>
    )
  }
}

export default WorkTemplate

export const pageQuery = graphql`
  query WorkBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        img {
          publicURL
        }
      }
    }
  }
`
